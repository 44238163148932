<template>
  <v-navigation-drawer app width="235" rail-width="64" color="white" :rail="rail" class="bg-primary-700 px-4 drawer" :model-value="true" :permanent="true">
    <template v-slot:prepend>
      <div class="d-flex justify-center my-7">
        <div class="d-flex align-center">
          <v-icon icon="mdi-menu" @click="rail = !rail" />
          <div class="ml-2" v-if="!rail">
            <v-img src="/images/logo-title.svg" alt="Logo" :width="117" />
          </div>
        </div>
      </div>
    </template>

    <template v-slot>
      <div class="overflow-visible" v-if="routes.length">
        <v-divider :thickness="2" color="primary-300"></v-divider>
        <p v-if="!rail" class="subtitle text-uppercase my-4">
          {{ t("drawer.subtitles.menu") }}
        </p>
        <v-list class="py-0 overflow-visible">
          <NavbarEntry v-if="!rail" v-for="route of routes" :route="route" :rail="rail" />
          <NavbarEntryRailed v-else v-for="route of routes" :route="route" :rail="rail" />
        </v-list>
      </div>
    </template>

    <template v-slot:append>
      <div>
        <v-divider :thickness="2" color="primary-300" class="my-3"></v-divider>
        <p v-if="!rail" class="subtitle text-uppercase mb-3">
          {{ t("drawer.subtitles.account") }}
        </p>
        <ProfileCard :class="rail ? '' : 'py-2 mx-2'" :rail="rail" />
        <div :class="rail ? '' : ' mx-2'">
          <LanguagePicker :rail="rail" />
        </div>

        <RessourcesRoute :rail="rail" v-if="auth.isAppAdmin() || auth.isCompanyAdminDashboard() || auth.isSiteManager()" />

        <v-divider :thickness="2" color="primary-300" class="mt-3"></v-divider>
        <AppLogoutConfirm :rail="rail" />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { subscribeNewUserNeedsApproval } from "@/services/user-service";
import { useAuthStore } from "@/store/auth";
import NavbarEntry from "@/components/atoms/NavbarEntry.vue";
import NavbarEntryRailed from "@/components/atoms/NavbarEntryRailed.vue";
import AppLogoutConfirm from "@/components/atoms/AppLogoutConfirm.vue";
import RessourcesRoute from "@/components/molecules/drawer/RessourcesRoute.vue";
import LanguagePicker from "@/components/molecules/drawer/LanguagePicker.vue";
import ProfileCard from "@/components/molecules/drawer/ProfileCard.vue";
import { useI18n } from "vue-i18n";
import { Route } from "@/services/common-service/models";

const { t } = useI18n();
const auth = useAuthStore();
const rail = ref(false);

const routes = computed<Route[]>(() =>
  [
    ...[
      (auth.isAppAdmin() || auth.isCompanyAdminDashboard() || auth.isSiteManager() || auth.isStandardUser()) && {
        id: "risk-management",
        title: t("drawer.riskManagement"),
        icon: "risks",
        to: "/risk-management",
      },
    ].filter((el) => el),
    {
      id: "home-group",
      title: t("drawer.home.title"),
      icon: "home",
      children: [
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminDashboard() || auth.isSiteManager() || auth.isStandardUser()) && {
            id: "safety",
            title: t("drawer.home.children.safety"),
            to: "/safety",
          },
        ],
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminChecklist()) && {
            id: "kpi",
            title: t("drawer.home.children.dashboard"),
            to: "/dashboard",
          },
        ],
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminDashboard() || auth.isSiteManager() || auth.isStandardUser()) && {
            id: "vehicle-kpi",
            title: t("drawer.home.children.vehicle"),
            to: "/vehicle-kpi",
          },
        ],
      ].filter((el) => el),
    },
    {
      id: "checklists-group",
      title: t("drawer.checklists.title"),
      icon: "checklists",
      children: [
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminChecklist()) && {
            id: "questions",
            title: t("drawer.checklists.children.questions"),
            to: "/questions",
          },
        ],
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminChecklist()) && {
            id: "checklists",
            title: t("drawer.checklists.children.checklists"),
            to: "/checklists",
          },
        ],
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminChecklist()) && {
            id: "segments",
            title: t("drawer.checklists.children.segments"),
            to: "/segments",
          },
        ],
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminChecklist()) && {
            id: "plannings",
            title: t("drawer.checklists.children.plannings"),
            to: "/plannings",
          },
        ],
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminChecklist()) && {
            id: "reports",
            title: t("drawer.checklists.children.reports"),
            to: "/reports",
          },
        ],
      ].filter((el) => el),
    },
    ...[
      (auth.isAppAdmin() || auth.isCompanyAdminDashboard() || auth.isSiteManager() || auth.isStandardUser()) && {
        id: "products",
        title: t("drawer.products"),
        icon: "products",
        to: "/products",
      },
    ].filter((el) => el),
    {
      id: "entities-group",
      title: t("drawer.entities.title"),
      icon: "entities",
      children: [
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminDashboard() || auth.isSiteManager() || auth.isStandardUser()) && {
            id: "worksites",
            title: t("drawer.entities.children.workistes"),
            to: "/worksites",
          },
        ],
        ...[
          (auth.isAppAdmin() || auth.isAnyCompanyAdmin() || auth.isSiteManager() || auth.isStandardUser()) && {
            id: "companies",
            title: t("drawer.entities.children.companies"),
            to: "/companies",
          },
          ,
        ],
        (auth.isAppAdmin() || auth.isAnyCompanyAdmin() || auth.isSiteManager()) && {
          id: "users",
          title: t("drawer.entities.children.users"),
          to: "/users",
          badge: hasNewUsersToApprove.value,
        },
        ...[
          (auth.isAppAdmin() || auth.isCompanyAdminChecklist()) && {
            id: "vehicles-types",
            title: t("drawer.entities.children.vehicleTypes"),
            to: "/vehicles-types",
          },
        ],
        ...[
          (auth.isAppAdmin() || auth.isAnyCompanyAdmin() || auth.isSiteManager() || auth.isStandardUser()) && {
            id: "vehicles",
            title: t("drawer.entities.children.vehicles"),
            to: "/vehicles",
          },
        ],
      ].filter((el) => el),
    },
    ...[
      (auth.isAnyCompanyAdmin() || auth.isSiteManager() || auth.isStandardUser() || auth.isInstaller()) && {
        id: "subscriptionsLicences",
        title: t("drawer.subscription-licenses.title-as-user"),
        icon: "requests",
        to: "/subscriptions-licenses",
      },
    ].filter((el) => el),

    ...[
      (auth.isAppAdmin() || auth.isLicenceManager()) && {
        id: "subscriptionsLicences-group",
        title: t("drawer.subscription-licenses.title"),
        icon: "requests",
        children: [
          {
            id: "subscriptionsLicences",
            title: t("drawer.subscription-licenses.children.overview"),
            icon: "requests",
            to: "/subscriptions-licenses",
          },
          {
            id: "requests",
            title: t("drawer.subscription-licenses.children.requests"),
            icon: "requests",
            to: "/requests",
          },
        ],
      },
    ].filter((el) => el),
  ].filter((el: Route) => el.to || (el?.children && el?.children.length > 0))
);

const hasNewUsersToApprove = subscribeNewUserNeedsApproval();
</script>

<style scoped>
.subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  color: rgba(var(--v-theme-primary-100), 0.7);
}

.drawer {
  font-size: 14px;
}
</style>
